import { Form } from "../form/model/Form";
import { View } from "../view/model/View";
import { MenuItem } from "../menuItem/model/MenuItem";
import { MenuHook } from "../menuItem/model/MenuHook";
import { Broadcast } from "../../../infrastructure/broadcast/broadcast";
import { ConfigurationEvents } from "./ConfigurationEvents";
import { ReportTemplate } from "../reportTemplate/model/ReportTemplate";
import { IAppSettings } from "@utils/IAppSettings";
import { Nullable, ViewType } from "@mrs/webclient-shared-ui-lib";
import {configuration, hooks, dto} from "@mrs/platform-configuration-client";
import Configuration = configuration.SingleTenantConfiguration;
import Hooks = hooks.HooksConfiguration
import {ConfigAccess} from "@utils/ConfigAccess";
import HOOK_TYPE = dto.hook.HOOK_TYPE;
import ViewDTO = dto.view.ViewDTO;

class ConfigurationAccessClass {
    _configuration: Configuration;
    _hooks: Hooks;
    _views: dto.view.ViewDTO[]; // удалить после обновления кастомных компонентов
    _tempHooks: Map<string, Map<HOOK_TYPE, (p0: any) => any>>

    constructor() {
        this._configuration = new Configuration();
        this._hooks = new Hooks();
    }

    async init(url: string): Promise<boolean> {
        await this._configuration.initAsync(url);
        await this._hooks.initAsync(url);
        this._views = await this._configuration.view.getAllAsync(null);
        this._tempHooks = await this.getAllHooks();
        Broadcast.trig(ConfigurationEvents.onInitialized, null);
        return true;
    }

    async clean() {
        await this._configuration.cleanAsync(null);
    }

    async getForm(name: string): Promise<Form | undefined> {
        const dto = JSON.parse(await this._configuration.form.getAsJsonAsync(name, null));
        if (!dto) return;
        return new Form(dto);
    }

    async getView(name: string): Promise<View | undefined> {
        const dto = await this._configuration.view.getAsync(name);
        if (!dto) return;
        return new View(dto);
    }

    getViewSync(name: string): View | undefined {
        const dto = this.firstOrNull(this._views, (view: ViewDTO) => view.name == name);
        if (!dto) return;
        return new View(dto);
    }

    private firstOrNull(array: any[], predicate: any) {
        const result = array.find(predicate);
        return result !== undefined ? result : null;
    }

    async getFormComponents(): Promise<Array<dto.ComponentDTO>> {
        try {
            return this._configuration.library.getModulesByTypeAsync("form", ConfigAccess.configUrl);
        } catch (e) {
            return [];
        }
    }

    async getViewComponentByType(viewType: ViewType): Promise<dto.ComponentViewDTO | null | undefined>  {
        try {
            return this._configuration.library.getModuleByViewTypeAsync(viewType, ConfigAccess.configUrl);
        } catch (e) {
            return null;
        }
    }

    async getMenuItems(): Promise<MenuItem[]> {
        const dto = await this._configuration.menu.getItemsAsync(null);
        return this.toEntities(MenuItem, dto);
    }

    async getMenuHook(): Promise<((p0: any[]) => any) | null> {
        try {
            if (this._hooks.getMenuHook() != null) {
                return this._hooks.getMenuHook()
            }
        } catch (e) {
            console.error(e)
        }
        const hook = await this._configuration.menu.getHookAsync();
        if (!hook) return null;

        return (new MenuHook(hook)).hook
    }

    async findHooksByFormName(formName: string): Promise<Map<HOOK_TYPE, (args: any) => any>> {
        let formHooks: Map<HOOK_TYPE, (args: any) => any>
        try {
            formHooks = this._hooks.getFormHooks(formName)
        } catch (e) {
            const legacyHooks = (await this._configuration.hook.getAllAsync(formName, null, null)).filter((item) => item.form === formName);
            formHooks = new Map();
            legacyHooks.forEach((hook) => {
                const func = new Function(`return (${hook.function})`)();
                formHooks.set(hook.hookType, func);
            });
        }
        return formHooks;
    }

    async getAllHooks(): Promise<Map<string, Map<dto.hook.HOOK_TYPE, (p: any) => Nullable<any>>>> {
        let allHooks: Map<string, Map<dto.hook.HOOK_TYPE, (p: any) => Nullable<any>>>
        try {
            allHooks = this._hooks.getAll()
        } catch (e) {
            const legacyHooks = (await this._configuration.hook.getAllAsync(null, null, null));
            allHooks = new Map();
            legacyHooks.forEach((hook) => {
                if (allHooks.has(hook.form)) {
                    const formHooks = allHooks.get(hook.form)!!;
                    const func = new Function(`return (${hook.function})`)();
                    formHooks.set(hook.hookType, func);
                    allHooks.set(hook.form, formHooks);
                }
            });
        }
        return allHooks;
    }

    async getCanCreateHook(formName?: Nullable<string>): Promise<((args: any) => any) | undefined> {
        if (!formName) {
            return undefined;
        }

        const hooksForForm = await this.findHooksByFormName(formName);
        const result = hooksForForm.get(dto.hook.HOOK_TYPE.CAN_CREATE);

        return result !== undefined && result !== null ? result : undefined;
    }


    getCanCreateHooksSync(formName?: Nullable<string>) {
        if (formName) {
            const hooksForForm = this._tempHooks.get(formName);
            if (hooksForForm) {
                return hooksForForm.get(dto.hook.HOOK_TYPE.CAN_CREATE);
            }
        }
        return null
    }

    async getReportTemplates(location: string) {
        const dto = await this._configuration.doctemplate.getAllAsync(location, null);
        return this.toEntities(ReportTemplate, dto);
    }

    async getConfigurationSettings(): Promise<IAppSettings> {
        return (await this._configuration.manifest.getAppAsync(null)) as IAppSettings;
    }

    private toEntities<TEntity, TDto>(
        domainClass: new (dto: TDto) => TEntity,
        rawObjects: TDto[],
    ) {
        return rawObjects.map((raw) => new domainClass(raw));
    }
}

export const ConfigurationAccess = new ConfigurationAccessClass();
