import React from "react";
import { createRoot } from "react-dom/client";
import {
    AnalyticsEventAction,
    AnalyticsEventComponent,
    AnalyticsEventLocation,
    AnalyticsEventType,
    ConfirmDialog,
    IAnalyticsEvent,
    IDOMElement,
    TVoidCallback,
} from "@mrs/webclient-shared-ui-lib";
import {
    IAddFormInstanceDialogParams,
    IAddFormInstanceDialogPresenter,
} from "./IAddFormInstanceDialogPresenter";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";
import { computed, observable, toJS } from "mobx";
import { i18next } from "../../../../../../lib/translator";
import { injectable } from "inversify";
import { AddFormInstanceDialog } from "../AddFormInstanceDialog";
import { FormInstance } from "../../../../../core/context/formInstance/model/FormInstance";
import { Form } from "../../../../../core/context/form/model/Form";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import { Notify } from "@utils/notify/Notify";
import assign from "lodash-es/assign";
import cloneDeep from "lodash-es/cloneDeep";
import { lazyInject } from "../../../../../core/AppDiContainer";
import { FormInstanceDiType } from "../../../../../core/context/formInstance/diType";
import { IFormInstanceService } from "../../../../../core/context/formInstance/service/IFormInstanceService";
import { ConfigurationAccess } from "../../../../../core/context/configuration/ConfigurationAccess";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";

@injectable()
export class AddFormInstanceDialogPresenter
    implements IAddFormInstanceDialogPresenter {
    @observable private _isOpen: boolean = false;
    @observable private _isShowSaveButton: boolean = false;
    @observable private _isSaving: boolean = false;
    @observable private _createdFormInstance: FormInstance | null = null;
    @observable private _form: Form | null = null;

    private popupElement: IDOMElement = {
        element: document.createElement("div"),
    };
    private _baseForm: Form | null = null;
    private _onClose?: () => void;
    private _onCreate?: TVoidCallback<FormInstance>;

    @lazyInject(FormInstanceDiType.IFormInstanceService)
    private readonly service: IFormInstanceService;

    @computed
    public get isOpen(): boolean {
        return this._isOpen;
    }

    @computed
    public get isShowSaveButton(): boolean {
        return this._isShowSaveButton;
    }

    @computed
    public get isSaving(): boolean {
        return this._isSaving;
    }

    @computed
    public get createdFormInstance(): FormInstance | null {
        return toJS(this._createdFormInstance);
    }

    @computed
    public get form(): Form | null {
        return toJS(this._form);
    }

    showModal = async (params: IAddFormInstanceDialogParams) => {
        this.popupElement.root = createRoot(this.popupElement.element);
        document.body.appendChild(this.popupElement.element);
        await this.requestCreatedFormInstance(params.data, params.initData);
        this._isOpen = true;
        this._onClose = params.onClose;
        this._onCreate = params.onCreate;
        this.renderModal();
    };

    onClickCreate = async () => {
        if (this._isSaving) return;
        let formInstance;
        try {
            this._isSaving = true;
            formInstance = await this.createFormInstance();
        } finally {
            this._isSaving = false;
        }
        this.logEvent({
            component: AnalyticsEventComponent.Save,
            action: AnalyticsEventAction.Click,
            params: {
                created: formInstance?.toDTO(),
            },
        });
        if (!!formInstance) {
            this._onCreate && this._onCreate(formInstance);
            this.onClose();
        }
    };

    onClickClose = async () => {
        this.logEvent({
            component: AnalyticsEventComponent.Close,
            action: AnalyticsEventAction.Click,
        });
        const message = i18next.t("common:common.confirmCloseWithChanges");
        if (Object.keys(this.createdFormInstance?.data || {}).length) {
            try {
                await ConfirmDialog("", message, {
                    confirmLabel: i18next.t("common:common.reset"),
                    cancelLabel: i18next.t("common:common.cancel"),
                    confirmUiTestId: "resetButton",
                    cancelUiTestId: "cancelButton",
                    titleVariant: "body1",
                    muiTheme: BaseTheme,
                });
                this.onClose();
            } catch (e) {
                console.error(e);
            }
        } else this.onClose();
    };

    onChangeFormData = (data: object) => {
        if (!this._createdFormInstance) return;
        try {
            const newData = assign(cloneDeep(this.createdFormInstance), {
                data,
            });
            this._createdFormInstance = newData;
            this.updateForm(newData, this._baseForm);
        } catch (e) {
            const message =
                (e as any).message ||
                i18next.t("common:formInstance.canNotUpdate");
            Notify.error({ message });
        }
    };

    showSaveButton = () => {
        this._isShowSaveButton = true;
    };

    private renderModal = () => {
        this.popupElement.root?.render(
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={BaseTheme}>
                    <AddFormInstanceDialog presenter={this} />
                </ThemeProvider>
            </StyledEngineProvider>,
        );
    };

    private requestCreatedFormInstance = async (
        params: object,
        initData?: object,
    ): Promise<void> => {
        this._createdFormInstance = this.service.createEntity(
            params,
            initData || null,
        );
        this._baseForm =
            await ConfigurationAccess.getForm(this._createdFormInstance.formId) ||
            null;
        this.updateForm(this._createdFormInstance, this._baseForm);
    };

    private createFormInstance = async () => {
        if (!this._createdFormInstance) return;
        let formInstance: FormInstance | null = null;
        this._createdFormInstance.createdDate = new Date().getTime();

        try {
            if (!!this.createdFormInstance) {
                formInstance = await this.service.createFormInstance(
                    this.createdFormInstance,
                );
            }
        } catch (e) {
            const message =
                (e as any).message ||
                i18next.t("common:formInstance.createError");
            Notify.error({ message });
        }
        return formInstance;
    };

    private updateForm = (formInstance: FormInstance, form: Form | null) => {
        const viewUpdateHook = formInstance.viewUpdate;
        const params = {
            item: formInstance.toDTO(),
            user: CurrentUser.getDtoWithParsedToken(),
            form: cloneDeep(form),
            isCreation: true,
        };
        this._form = viewUpdateHook ? viewUpdateHook(params) : form;
    };

    private onClose = () => {
        if (!document.body.contains(this.popupElement.element)) return;
        this.popupElement.root?.unmount();
        this.popupElement.element.remove();
        this._isOpen = false;
        this._isShowSaveButton = false;
        this._createdFormInstance = null;
        this._form = null;
        this._onClose && this._onClose();
    };

    private logEvent = (
        data: Omit<IAnalyticsEvent, "type" | "location">,
    ): void => {
        AnalyticsService.logEvent({
            type: AnalyticsEventType.System,
            location: AnalyticsEventLocation.FormFullscreen,
            ...data,
        });
    };
}
