import React from "react";
import { IAppMainLayoutPresenter, IPageInfo } from "./IAppMainLayoutPresenter";
import { computed, observable, toJS } from "mobx";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import differenceBy from "lodash-es/differenceBy";
import { MenuItem } from "../../../../../core/context/menuItem/model/MenuItem";
import { MRSIcon } from "@mrs/webclient-shared-ui-lib";
import { getViewPath, getViewPathTemplate, Routes } from "@ui/Routes";
import { ViewScreen } from "@ui/screens/ViewScreen/ViewScreen";
import { SettingsScreen } from "@ui/screens/SettingsScreen/SettingsScreen";
import { i18next } from "../../../../../../lib/translator";
import { LoggerService } from "@lib/logger/LoggerService";
import { User } from "../../../../../core/context/user/model/User";
import { Broadcast } from "../../../../../infrastructure/broadcast/broadcast";
import { CurrentUserEvents } from "../../../../../core/context/user/CurrentUserEvents";
import { ConfigurationAccess } from "../../../../../core/context/configuration/ConfigurationAccess";
import { ConfigAccess } from "@utils/ConfigAccess";
import cloneDeep from "lodash-es/cloneDeep";
import { IAppLogo, IAppSettings } from "@utils/IAppSettings";
import { lazyInject } from "../../../../../core/AppDiContainer";
import { ApplicationDiType } from "@ui/diType";
import { IAuthService } from "../../../../../core/context/auth/service/IAuthService";
import { AuthEvents } from "../../../../../core/context/auth/AuthEvents";

const defaultUrl = "../../../../../favicon.svg";

export class AppMainLayoutPresenter implements IAppMainLayoutPresenter {
    @observable private _systemPages: IPageInfo[] = [];
    @observable private _customPages: IPageInfo[] = [];
    @observable private _currentUser: User | null = null;
    @observable private _isInitialized: boolean = false;
    @observable private _configurationSettings!: IAppSettings;
    private _appSettings: IAppSettings = ConfigAccess.config.app;

    @lazyInject(ApplicationDiType.IAuthService)
    private readonly authService: IAuthService;

    constructor() {
        Broadcast.on(CurrentUserEvents.onUpdated, this.requestUser, this);
    }

    public get pages(): IPageInfo[] {
        return [
            {
                id: "settings",
                icon: MRSIcon.SETTINGS,
                title: i18next.t("common:pages.settings"),
                link: Routes.SETTINGS,
                urlTemplate: Routes.SETTINGS,
                component: <SettingsScreen />,
            },
        ];
    }

    @computed
    public get systemPages(): IPageInfo[] {
        return toJS(this._systemPages);
    }

    @computed
    public get customPages(): IPageInfo[] {
        return toJS(this._customPages);
    }

    @computed
    public get currentUser(): User | null {
        return toJS(this._currentUser);
    }

    @computed
    public get favicon(): string {
        if (this._appSettings?.favicon) return this._appSettings.favicon;
        if (this._isInitialized && this._configurationSettings?.favicon)
            return this._configurationSettings.favicon;
        return defaultUrl;
    }

    @computed
    public get logo(): IAppLogo | null {
        if (this._appSettings?.logo) return this._appSettings.logo;
        if (this._isInitialized && this._configurationSettings?.logo)
            return this._configurationSettings.logo;
        return null;
    }

    @computed
    public get menuTitle(): string | null {
        if (this._appSettings?.caption) return this._appSettings.caption;
        if (this._isInitialized && this._configurationSettings?.caption)
            return this._configurationSettings.caption;
        return null;
    }

    init = async () => {
        this.requestUser();
        LoggerService.init();
        const configurationUrl =
            process.env.CONFIGURATION_URL ||
            ConfigAccess.configUrl;
        await ConfigurationAccess.init(configurationUrl);
        this._configurationSettings = await ConfigurationAccess.getConfigurationSettings();
        this._isInitialized = true;
        this.setAppTitle();
        this._systemPages = this.pages;
        await this.requestCustomPages();
    };

    logout = () => {
        this.authService.logout();
        ConfigAccess.removeConfig();
        ConfigAccess.removeConfigUrl();
        Broadcast.trig(AuthEvents.logout, null);
    };

    private requestUser = () => {
        this._currentUser = CurrentUser.getCurrentUser();
    };

    private async requestCustomPages() {
        const configurationMenuItems = await ConfigurationAccess.getMenuItems() || [];
        const hook: any = await ConfigurationAccess.getMenuHook();
        const menuItems = (
                hook({
                menuItems: cloneDeep(configurationMenuItems),
                user: CurrentUser.getDtoWithParsedToken(),
            }) || configurationMenuItems
            ).map(this.toPage)
        const systemPages = menuItems.filter((item: IPageInfo) => !!item.isSystem);
        this._systemPages.unshift(...systemPages);
        this._customPages = differenceBy(menuItems, systemPages);
    }

    private setAppTitle() {
        const title = document.getElementsByTagName("title")[0];
        if (this._appSettings?.title) {
            title.innerText = this._appSettings.title;
            return;
        }
        if (this._isInitialized && this._configurationSettings.title) {
            title.innerText = this._configurationSettings.title;
            return;
        }
    }

    private toPage = (menuItem: MenuItem): IPageInfo => {
        return {
            id: "view" + menuItem.viewId,
            viewId: menuItem.viewId,
            icon: menuItem.icon,
            title: menuItem.title,
            isSystem: menuItem.isSystem,
            urlTemplate: getViewPathTemplate(),
            link: getViewPath(menuItem.viewId),
            component: <ViewScreen />,
        };
    };
}
