import {
    ICustomJsonForm,
    TProperties,
} from "../dto/IFormDTO";
import { ObjectId } from "@mrs/webclient-shared-ui-lib";
import { dto } from "@mrs/platform-configuration-client";
import { Identifiable } from "src/app/core/sharedKernel/model/Identifiable";
import { ITab, TabType } from "../dto/TypeDefinition";

export class Form extends Identifiable {
    private readonly _title: string;
    private readonly _code: string;
    private readonly _scheme: ICustomJsonForm;
    private readonly _tags: ObjectId[];
    constructor(dto: dto.form.FormDTO) {
        super(dto);
        this._title = dto.title || "";
        this._code = dto.code || "";
        this._tags = dto.tags;
        this._scheme = this.toSchema(dto.scheme);
    }

    get title(): string {
        return this._title;
    }

    get code(): string {
        return this._code;
    }

    get scheme(): ICustomJsonForm {
        return this._scheme;
    }

    get tags(): ObjectId[] {
        return this._tags;
    }

    private toSchema(data: dto.form.Scheme): ICustomJsonForm {
        return {
            required: data.required || [],
            order: data.order || [],
            tabs: data.tabs?.map(this.toTab) || [],
            title: data.title || "",
            properties: this.convertArrayToRecordByName(data.properties)
        };
    }

    private convertArrayToRecordByName(array: any[]):  TProperties {
        const result: TProperties = {};

        array.forEach(item => {
            result[item.name] = item;
        });

        return result;
    }

    private toTab(tab: dto.form.tabs.Tab): ITab {
        return {
            ...tab,
            name: tab.name,
            title: tab.title,
            type: (tab.type as unknown) as TabType,
            hidden: tab.hidden || false,
            disabled: tab.disabled || false,
        };
    }
}
