import React from "react";
import { ICustomFormUIController } from "./ICustomFormUIController";
import { JSONSchema7 } from "json-schema";
import cloneDeep from "lodash-es/cloneDeep";
import set from "lodash-es/set";
import { Form } from "../../../../../core/context/form/model/Form";
import {
    CUSTOM_JSON_FORM_TYPE,
    ICustomJsonForm,
} from "../../../../../core/context/form/dto/IFormDTO";
import { UiSchema } from "@rjsf/utils";
import { ConfigurationAccess } from "../../../../../core/context/configuration/ConfigurationAccess";
import {
    CustomFormUIKey,
    ICustomForm,
    ICustomFormData,
    ICustomFormDataWithUIKey,
    ICustomFormFields,
    ICustomFormWidgets,
} from "../ICustomForm";
import {
    getFieldData,
    getWidgetData,
} from "../components/CustomFormComponents";
import { IFormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/IFormWrapped";
import { FormFieldWrapped } from "@ui/components/shared/CustomForm/wrapped/FormFieldWrapped";
import { FormWidgetWrapped } from "@ui/components/shared/CustomForm/wrapped/FormWidgetWrapped";
import { RemoteComponent } from "@ui/components/shared/RemoteComponent/RemoteComponent";
import { ViewModuleInitializer } from "@ui/components/view/shared/ViewModuleInitializer";
import {
    IAnalyticsEvent,
    IFormWrapped,
    ObjectId,
    TProperty,
} from "@mrs/webclient-shared-ui-lib";
import { DialogViewManager } from "@ui/components/managers/DialogViewManager/DialogViewManager";
import { createLoadRemoteModule } from "@ui/components/shared/RemoteComponent/LoadRemoteModule";
import { CurrentUser } from "../../../../../core/context/user/currentUser/CurrentUser";
import { lazyInject } from "../../../../../core/AppDiContainer";
import { DocumentDiType } from "../../../../../core/context/document/diType";
import { IDocumentController } from "../../../../../core/context/document/controller/IDocumentController";
import { ApplicationDiType } from "@ui/diType";
import { IUserCollection } from "../../../../../core/context/user/collection/IUserCollection";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import { Notify } from "@utils/notify/Notify";
import { IPostController } from "../../../../../core/context/post/controller/IPostController";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";

const emailPattern = `^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+)$`;

class CustomFormUIControllerClass implements ICustomFormUIController {
    private _widgetData: ICustomFormData;
    private _fieldData: ICustomFormData;
    private _formDataWidgets: ICustomFormDataWithUIKey;
    private _formDataFields: ICustomFormDataWithUIKey;
    private _formMap: Map<string, Form> = new Map();

    @lazyInject(DocumentDiType.IDocumentController)
    private readonly documentController: IDocumentController;

    @lazyInject(ApplicationDiType.IPostController)
    private readonly postController: IPostController;

    @lazyInject(ApplicationDiType.IUserCollection)
    private readonly userCollection: IUserCollection;

    async init() {
        this._widgetData = getWidgetData();
        this._fieldData = { ...getFieldData(), ...(await this.getCustomFieldData()) };
        this._formDataWidgets = this.getCustomFormDataWithUIKey(
            this._widgetData,
            CustomFormUIKey.Widget,
        );
        this._formDataFields = this.getCustomFormDataWithUIKey(
            this._fieldData,
            CustomFormUIKey.Field,
        );

        Object.keys(this._formDataFields).forEach((key) => {
            set(this._formDataFields, `${key}.options`, {
                currentUser: {
                    getDtoWithParsedToken: (excludePrivateFields?: boolean) =>
                        CurrentUser.getDtoWithParsedToken(excludePrivateFields),
                    getToken: () => CurrentUser.getToken(),
                },
                DialogViewManager,
                viewModuleInitializer: ViewModuleInitializer,
                notify: Notify,
                theme: BaseTheme,
                getView: (id: ObjectId) => ConfigurationAccess.getViewSync(id),
                getUserById: (id: ObjectId) =>
                    this.userCollection.getById(id)?.toDTO(),
                logEvent: (ev: IAnalyticsEvent) =>
                    AnalyticsService.logEvent(ev),
                documentController: this.documentController,
                postController: this.postController,
            });
        });
    }

    async getFormByName(name: string): Promise<Form | undefined> {
        let form = this._formMap.get(name);
        if (form) return form;
        form = await ConfigurationAccess.getForm(name);
        form && this._formMap.set(name, form);
        return form;
    }

    getCustomWidgets(): ICustomFormWidgets {
        const widgetWrapped = (props: IFormWidgetWrapped) => {
            return new FormWidgetWrapped(props).toPlainObject();
        };

        return this.getCustomComponents(this._widgetData, widgetWrapped);
    }

    getCustomFields(): ICustomFormFields {
        const fieldWrapped = (props: IFormWrapped) => {
            return new FormFieldWrapped(props).toPlainObject();
        };

        return this.getCustomComponents(this._fieldData, fieldWrapped);
    }

    async parseCustomJsonForm(
        jsonForm: ICustomJsonForm,
    ): Promise<{ schema: JSONSchema7; uiSchema: UiSchema }> {
        const uiSchemaMap: ICustomFormDataWithUIKey = {
            ...this._formDataWidgets,
            ...this._formDataFields,
        };
        const additionalProperties = {
            [CUSTOM_JSON_FORM_TYPE.CHECKBOXES]: {
                uniqueItems: true,
            },
            [CUSTOM_JSON_FORM_TYPE.RADIO]: {
                uniqueItems: true,
            },
            [CUSTOM_JSON_FORM_TYPE.EMAIL]: {
                pattern: emailPattern,
            },
        };
        const typeMap = {};
        for (const [key, value] of Object.entries(uiSchemaMap)) {
            set(typeMap, key, value.type);
        }

        const schemaInfo = this.getParsedSchema(
            jsonForm,
            typeMap,
            uiSchemaMap,
            additionalProperties,
        );
        const { parsedSchema, parsedUiSchema } = schemaInfo;

        return { schema: parsedSchema, uiSchema: parsedUiSchema };
    }

    private getParsedSchema(
        jsonForm: TProperty,
        typeMap: TProperty,
        uiSchemaMap: TProperty,
        additionalProperties: TProperty,
        depth: number = 0,
    ): { parsedSchema: JSONSchema7; parsedUiSchema: UiSchema } {
        const parsedSchema: JSONSchema7 = {
            type: "object",
            title: depth > 0 ? jsonForm.title : undefined,
            required: jsonForm.required,
            // @ts-ignore
            hidden: jsonForm?.hidden || false,
            depth,
        };
        const parsedUiSchema: UiSchema = {};
        if (jsonForm.order) {
            const fieldCount = Object.keys(jsonForm.properties).length;
            parsedUiSchema["ui:order"] = [...jsonForm.order];
            if (jsonForm.order.length < fieldCount) {
                parsedUiSchema["ui:order"].push("*");
            }
        }
        const formProperties = jsonForm.properties;
        Object.keys(formProperties).forEach((fieldName) => {
            const formProperty = formProperties[fieldName];
            const type = formProperty.type;
            const hidden = formProperty.hidden;
            const disabled = formProperty.disabled;

            if (hidden && formProperty.lazyLoading !== false) {
                const params =
                    type !== CUSTOM_JSON_FORM_TYPE.ARRAY
                        ? { [CustomFormUIKey.Widget]: "hidden" }
                        : { [CustomFormUIKey.Field]: () => "" };
                set(parsedUiSchema, fieldName, params);
                return;
            }
            if (
                type === CUSTOM_JSON_FORM_TYPE.OBJECT &&
                formProperty.properties
            ) {
                const schemaInfo = this.getParsedSchema(
                    formProperty,
                    typeMap,
                    uiSchemaMap,
                    additionalProperties,
                    depth + 1,
                );
                set(parsedUiSchema, fieldName, schemaInfo.parsedUiSchema);
                set(
                    parsedSchema,
                    `properties.${fieldName}`,
                    schemaInfo.parsedSchema,
                );
            } else {
                if (!uiSchemaMap[type]) return;

                set(parsedUiSchema, fieldName, cloneDeep(uiSchemaMap[type]));
                const fieldAdditionalProperties =
                    additionalProperties[type] || {};
                set(parsedSchema, `properties.${fieldName}`, {
                    ...formProperty,
                    ...fieldAdditionalProperties,
                    type: typeMap[type],
                });
                this.setEnumDisabled(
                    parsedUiSchema,
                    fieldName,
                    formProperty?.enumDisabled,
                );
                !!disabled &&
                    set(
                        parsedUiSchema,
                        `${fieldName}["ui:disabled"]`,
                        disabled,
                    );
                const propertyItems = formProperty.items;
                if (!!propertyItems) {
                    const itemType =
                        propertyItems?.type || CUSTOM_JSON_FORM_TYPE.TEXT;
                    if (uiSchemaMap[itemType]) {
                        set(
                            parsedUiSchema,
                            `${fieldName}.items`,
                            uiSchemaMap[itemType],
                        );
                    }
                    this.setEnumDisabled(
                        parsedUiSchema,
                        fieldName,
                        propertyItems?.enumDisabled,
                    );
                    set(parsedSchema, `properties.${fieldName}.items`, {
                        ...propertyItems,
                        type: typeMap[itemType],
                    });
                }
            }
        });
        return { parsedSchema, parsedUiSchema };
    }

    private setEnumDisabled(object: object, fieldName: string, value?: any) {
        !!value && set(object, `${fieldName}["ui:enumDisabled"]`, value);
    }

    private getCustomComponents<T>(
        data: ICustomFormData,
        wrapped: (props: T) => object,
    ) {
        const result = {};
        for (const [key, value] of Object.entries(data)) {
            set(result, key, (formData: T) => {
                const props = wrapped(formData);
                if (!!value.script) {
                    const module = createLoadRemoteModule(value.script);
                    return <RemoteComponent module={module} {...props} />;
                }
                if (!!value?.component) {
                    return React.createElement(value.component, { ...props });
                }
                return <div />;
            });
        }
        return result;
    }

    private getCustomFormDataWithUIKey(
        data: ICustomFormData,
        uiKey: CustomFormUIKey,
    ) {
        const result: ICustomFormDataWithUIKey = {};
        for (const [key, value] of Object.entries(data)) {
            result[key] = {
                ...value,
                [uiKey]: value.fieldName,
            };
        }
        return result;
    }

    private async getCustomFieldData(): Promise<ICustomFormData> {
        const formComponents = await ConfigurationAccess.getFormComponents();
        const result: ICustomFormData = {};
        formComponents.forEach((value: any) => {
            const data: ICustomForm = {
                type: value.schemaType,
                script: value.script,
                fieldName: value.fieldName,
            };
            set(result, value.fieldName, data);
        });
        return result;
    }
}

export const CustomFormUIController = new CustomFormUIControllerClass();
