import { IViewModuleInitializer } from "./IViewModuleInitializer";
import {
    IPagination,
    IQueryData,
    IQueryItem,
    IViewForCreate,
    IViewParams,
    IViewPresenter,
    ObjectId,
    TQueryParams,
    ViewType,
} from "@mrs/webclient-shared-ui-lib";
import { CurrentUser } from "../../../../core/context/user/currentUser/CurrentUser";
import { View } from "../../../../core/context/view/model/View";
import {
    IAddViewItemDialogPresenter,
    IViewItemDialogPresenter,
} from "../uiInterfaces/IViewItemDialogPresenter";
import { ObjectType } from "../../../../core/ObjectType";
import { lazyInject, useInject } from "../../../../core/AppDiContainer";
import { ApplicationDiType } from "@ui/diType";
import { IQueryService } from "../../../../infrastructure/queryService/IQueryService";
import get from "lodash-es/get";
import {
    addDialogPresenterByType,
    itemDialogPresenterByType,
} from "../uiInterfaces/PresenterByType";
import { Broadcast } from "../../../../infrastructure/broadcast/broadcast";
import { PathUtils } from "@utils/path/PathUtils";
import { FormInstanceDiType } from "../../../../core/context/formInstance/diType";
import { ConfigAccess } from "@utils/ConfigAccess";
import { ConfigurationAccess } from "src/app/core/context/configuration/ConfigurationAccess";
import { IFormInstanceService } from "../../../../core/context/formInstance/service/IFormInstanceService";
import { createLoadRemoteModule } from "@ui/components/shared/RemoteComponent/LoadRemoteModule";
import { AnalyticsService } from "@lib/analitics/AnalyticsService";
import { Notify } from "@utils/notify/Notify";
import { BaseTheme } from "@ui/theme/baseTheme/BaseTheme";

const VIEW_ITEM_ID = "view-item-id";

interface IAction {
    get: string;
    remove: string;
}

interface IServiceByType {
    service: string;
    action: IAction;
}

export class ViewModuleInitializer implements IViewModuleInitializer {
    private readonly _view: View;
    private readonly _viewType: ViewType;
    private _viewPresenter: IViewPresenter;
    private _queries: Record<string, IQueryData> = {};

    @lazyInject(ApplicationDiType.IQueryService)
    private readonly queryService: IQueryService<any>;

    @lazyInject(FormInstanceDiType.IFormInstanceService)
    private readonly formInstanceService: IFormInstanceService;

    constructor(view: View, viewType: ViewType) {
        this._view = view;
        // viewType необходимо передавать отдельным параметром из-за отображения ViewField в виде списка
        this._viewType = viewType;
        this.setQueryRecord(view.queries);
    }

    async init(): Promise<void> {
        this._viewPresenter = await this.toViewPresenter(this._viewType);
    }

    public get presenter() {
        return this._viewPresenter;
    }

    public get queries() {
        return this._queries;
    }

    public get currentQuery() {
        return this.presenter.currentQuery;
    }

    private get currentUser() {
        return CurrentUser.getDtoWithParsedToken(true);
    }

    private get type(): ObjectType | undefined {
        return this.currentQuery?.query?.type as ObjectType;
    }

    initView = async (
        data: Partial<IViewParams> & Pick<IViewParams, "scrollableTarget">,
        variables?: object,
        pagination?: IPagination,
    ) => {
        const { presenter, queries, ...other } = this;

        const params: IViewParams = {
            view: this._view,
            queries,
            logEvent: AnalyticsService.logEvent,
            configUrl: ConfigAccess.configUrl,
            notify: Notify,
            theme: BaseTheme,
            broadcast: Broadcast,
            currentUser: {
                getDtoWithParsedToken: (excludePrivateFields?: boolean) =>
                    CurrentUser.getDtoWithParsedToken(excludePrivateFields),
                getToken: () => CurrentUser.getToken(),
            },
            ...data,
            ...other,
        };
        await presenter.initView(params, variables, pagination);
    };

    // todo: кажется, нигде не используется, мб стоит удалить?
    getCanCreateHook = async (formId: string) => {
        return await ConfigurationAccess.getCanCreateHook(formId);
    };

    getViewItemDialogPresenter = () => {
        if (!this.type) return;
        const presenterByType = itemDialogPresenterByType[this.type];
        if (!!presenterByType) {
            return useInject<IViewItemDialogPresenter>(presenterByType);
        }
    };

    getAddViewItemDialogPresenter = (type: string) => {
        const presenterByType = addDialogPresenterByType[type];
        if (!!presenterByType) {
            return useInject<IAddViewItemDialogPresenter>(presenterByType);
        }
    };

    onAddItem = async (data: IViewForCreate, initData?: object) => {
        if (!data.type) return;
        const dialogPresenter = this.getAddViewItemDialogPresenter(data.type);
        if (!!dialogPresenter) {
            this.removeEventListener();
            await dialogPresenter.showModal({
                data,
                initData: {
                    ...initData,
                    ...this.presenter.variables,
                },
                onClose: this.onCloseItemDialog,
                onCreate: this.presenter.onCreate,
            });
            this.presenter.clearSelected();
        }
    };

    canDelete = async (item: any): Promise<boolean> => {
        if (this.type !== ObjectType.FORM_INSTANCE) return true;
        const entity = await this.getMethodByType("get", item.id);
        return entity?.canDelete() || false;
    };

    changeFormInstance = async (id: ObjectId, changes: object) => {
        return this.formInstanceService.changeFormInstance(id, changes);
    };

    openItemDialog = async (id: ObjectId) => {
        const itemDialog = document.getElementById(id);
        if (!!itemDialog) return;

        const dialogPresenter = this.getViewItemDialogPresenter();
        if (!!dialogPresenter) {
            this.removeEventListener();
            this.setPathKey(VIEW_ITEM_ID, id);
            this.presenter.setIsOpenItemDialog(true);
            await dialogPresenter.showModal({
                id,
                onClose: this.onCloseItemDialog,
            });
        }
    };

    removeEntitiesByIds = async (ids: ObjectId[]) => {
        await this.getMethodByType("remove", ids);
    };

    private setQueryRecord = (queries: IQueryItem[]) => {
        queries.forEach((value: IQueryItem) => {
            const queryName = value.name;
            this._queries[queryName] = {
                ...value,
                fetchData: async (
                    params?: TQueryParams,
                    variables?: object,
                ) => {
                    return this.getViewItems(queryName, params, variables);
                },
            };
        });
    };

    private getViewItems = async (
        queryName: string,
        params: TQueryParams = {},
        variables: object = {},
    ) => {
        const filterQueryParam = Object.keys(variables).length
            ? this.getFilterQueryParam(variables)
            : {};
        const queryParams: TQueryParams = {
            ...params,
            ...filterQueryParam,
            currentUser: this.getEncodeComponent(this.currentUser),
        };
        return this.queryService.getViewItems(
            this._view.name,
            queryParams,
            queryName,
        );
    };

    private removeEventListener = () => {
        this.presenter.removeEventListener();
    };

    private onCloseItemDialog = () => {
        this.presenter.setIsOpenItemDialog(false);
        this.removePathKey(VIEW_ITEM_ID);
        this.presenter.addEventListener();
    };

    private getMethodByType = (actionType: "get" | "remove", arg: any) => {
        const serviceByType: Record<string, IServiceByType> = {
            [ObjectType.FORM_INSTANCE]: {
                service: FormInstanceDiType.IFormInstanceService,
                action: {
                    get: "getById",
                    remove: "remove",
                },
            },
        };
        const item = get(serviceByType, this.type || "");
        if (!item) return;

        const { service, action } = item;

        return useInject<any>(service)[action[actionType]](arg);
    };

    private setPathKey(key: string, value: string) {
        history.pushState(
            null,
            "",
            PathUtils.setKey(window.location.pathname, key, value),
        );
    }

    private removePathKey(key: string) {
        history.pushState(
            null,
            "",
            PathUtils.removeKey(window.location.pathname, key),
        );
    }

    private getFilterQueryParam(variables: object) {
        return {
            filters: this.getEncodeComponent(variables),
        };
    }

    private getEncodeComponent(variables: any): string {
        return encodeURIComponent(JSON.stringify(variables));
    }

    private async toViewPresenter(viewType: ViewType) {
        const viewComponent = await ConfigurationAccess.getViewComponentByType(
            viewType,
        );

        if (!!viewComponent?.script) {
            const module = createLoadRemoteModule(viewComponent?.script);
            return new (module.default as any)();
        }
        return useInject<IViewPresenter>(viewType);
    }
}
